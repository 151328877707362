/* TODO: Give attribution for image or replace */
.HomePage {
    background-image: url("./pexels-brady-knoll-5914157-full.jpg");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    height: 50rem;
    /* min-height: ; */
    width: 100%;
}

.HomePage-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    margin: auto;
    gap: 20px;
}

.HomePage-branding-logo {
    padding: 5vh 0 0 0;
    @apply text-brand

}

.HomePage-location-input-button {
    width: 100%;
    max-width: 600px;
    /* flex: 1 1 auto; */
    cursor: pointer;
}