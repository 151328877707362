/* Universal Styles */

html {
  font-size: 18px;
  /* TODO: Confirm that I want to use border-box */
  box-sizing: border-box;
}

@media only screen and (max-width: 560px) {
  html {
    /* 16px is minimum to stop Safari from zooming to input */
    /* Ref: https://www.expiredqueues.com/css/stop-zoom-in-on-input-focus-on-mobile-devices/ */
    font-size: 16px;
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #000;
}

.image-container img {
  width: 100%;
}

/* App Styles */

.App {
  /* padding: 1.5rem; */
  font-family: Comfortaa, "Work Sans", sans-serif;
  /* background-image: url("./pexels-brady-knoll-5914157-full.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; */
  /* border: 1px solid blue; */
  /* background-color: yellow; */
}

h1 {
  
  /* background-color: #cca353; */
  font-family: Comfortaa, sans-serif;
  /* color: #fff; */
}
