.facility-page {
    @apply 
        flex
        justify-center
        py-6;
}

.facility-page__wrapper {
    @apply
        max-w-4xl;
}


.facility-page__back {
    
}

.facility-page__media-frame {

}

.facility-page__media {
 
}

.facility-page__header {
    @apply
        py-3;
}

.facility-page__title {
    @apply
        text-2xl;
}

.facility-page__org {
    @apply
        text-lg;
}