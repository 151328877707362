.FacilitiesPage {
    display: block;
    /* position: relative; */
}

.FacilitiesPage-search-header-outer {
    /* position: sticky; */
    top: 0;
    background-color: white;
}

.FacilitiesPage-search-results-container {
    display: flex;
}

.SearchPage-search-results-container {
    @apply p-3;
}

.FacilitiesPage-list {
    padding: 1rem;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(100vh - var(--facilities-search-header-offset,72px));
    /* width: var(--gp-fixed-sidebar-feed-width,50vw); */
    width: 450px;
    overflow-y: auto;
}
.FacilitiesPage-map {
    height: calc(100vh - var(--facilities-search-header-offset,72px)) !important;
    max-height: 1000px;
    overflow: hidden;
    display: block;
    position: static;
    top: auto;
    width: 100%;
}

.FacilitiesPage-update-list-button {
    position: relative;
}

/* TODO: Make less fragile... make variable as referenced below */
@media screen and (max-width:560px) {
    .FacilitiesPage-map, .FacilitiesPage-list {
        width: 100%;
        height: calc(100vh - var(--facilities-search-header-offset,116.75px));
    }
}

@media screen and (max-width:624px) {
    .FacilitiesPage-map, .FacilitiesPage-list {
        /* flex: 1 1 auto; */
        /* min-width: 0px; */
        width: 100%;
        height: calc(100vh - var(--facilities-search-header-offset,132.5px));
    }
}
