.Button {
    height: 100%;
    @apply whitespace-nowrap;
}
.Button-sm {
    @apply py-1 px-4 text-sm;
}
.Button-md {
    @apply py-2 px-5 text-lg;
}
.Button-lg {
    @apply py-3 px-6 text-lg;
}

.Button-primary {
    @apply bg-brand hover:bg-brand-hover text-white rounded-full focus:ring;;
}
.Button-secondary {
    @apply bg-interactive hover:bg-interactive-hover text-gray-700 rounded-full focus:ring;;
}
.Button-tertiary {
    @apply text-brand hover:text-brand-hover bg-transparent underline;
}

.Button-inactive {
    /* Need to update to something better */
    opacity: 0.5;
}