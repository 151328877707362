.FlexDatesConsecutiveDays {
    display: flex;
    flex-direction: column;
}
.FlexDatesConsecutiveDays label {
    @apply py-1 text-sm text-gray-400;
}
.FlexDatesConsecutiveDays-input-container {
    display: flex;
    flex-direction: row;
}
.FlexDatesConsecutiveDays-value {
    width: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @apply py-1 px-4 text-lg text-gray-700;
}