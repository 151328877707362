.DateSelectionInput {
    margin-top: 12px;
    padding-top: 12px;
    /* border-top: 1px solid #dfdfdf; */
    width: 100%;
    color: #00063C;
}
.DateSelectionInput-label {
    text-align: center;
    @apply py-1 px-4 text-lg text-gray-700;
}

.DateSelectionInput-date-type-selection {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.DateSelectionInput-flex-dates {
    /* height: 400px; */
    margin: 0 auto;
    /* border: dashed #eccaca; */
}

.DateSelectionInput-switch-date-type {
    width: 100%;
    text-align: center;
    /* overflow-wrap: break-word; */
    /* white-space: normal; */
}

.DateSelectionInput-specific-dates {
    /* height: 400px; */
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    /* border: dashed #eccaca; */
}
.DateSelectionInput-date-picker-wrapper input {
    max-width: 150px;
}

.DateSelectionInput-date-picker input {
    flex: 1 1 auto;
    padding: 16px;
    border: 1px solid var(--gray-400); 
    border-radius: 10px;
    cursor: pointer;
    /* Tailwind wasn't working here */
    /* @apply border-gray-400 rounded-md;  */
}