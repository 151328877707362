.facility-card {
    min-width: 400px;
    /* min-height: 400px; */
    height: 100%;
    @apply
        shadow-light
        border-light
        border
        border-solid
        rounded-std
        p-4
        w-full
        hover:shadow-heavy;
}

.facility-card__media-frame {
    @apply
        overflow-hidden
        rounded-small
        h-56
        md:h-48
        shadow-inner;
}

.facility-card__media {
    @apply rounded-small h-full min-w-full;
    max-width:unset;
}

.facility-card__content {
    @apply
        inline-flex
        flex-col
        gap-1
        pt-3
        w-full;
}

.facility-card__content__facility-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @apply
        overflow-hidden
        font-bold
        text-lg
        hover:text-brand-hover;
}

.facility-card__content__org-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @apply
        text-gray-700
        text-sm
        truncate pb-3;
}

.facility-card__availability-tag {
    @apply 
        inline-block
        bg-gray-200
        rounded-full
        font-semibold
        text-gray-700
        px-2
        py-1
        text-xs;
}
facility-card__availability-tag__date {
}

.facility-card__availability-tag__site-count {
    margin: 0 0 0 0.5rem;
    @apply
        rounded-full
        font-semibold
        text-gray-400
}

.facility-card__rating-tag {
    @apply 
        inline-block
        rounded-full
        text-gray-500
        px-3
        py-1
        text-xs;
}