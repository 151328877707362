.DateChangeModal {
    position: absolute;
    right: -100px;
    /* transform: translate(-12px, -12px); */
    padding: 12px;
    background-color: #FFFFFF;
    width: 330px;
    z-index: 999;
    border-radius: 36px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.DateChangeModal-done-btn {
    @apply py-3;
}

@media screen and (max-width:624px) {
    .DateChangeModal {
        position: fixed;
        transform: translate(0px, 0px);
        border-radius: 36px 36px 0 0;
        top: 24px;
        left: 0px;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
}