.FacilitiesSearchHeader {
    /* position: absolute; */
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: row;
}
.FacilitiesSearchHeader-section1 {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.FacilitiesSearchHeader-section1-logo {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.4rem 0rem 0.8rem;
    height: 100%;
}
.FacilitiesSearchHeader-section1-logo-text {
    @apply 
        text-brand
        text-2xl
        font-extrabold
}
.FacilitiesSearchHeader-section1-location {
    flex: 1 0 auto;
    margin: 8px;
}
.FacilitiesSearchHeader-section2 {
    display: flex;
    flex-direction: row;
    margin: 10px;
    gap: 10px;
}

/* On desktop the map is always shown, so we don't need the View Map button */
.FacilitiesSearchHeader-view-map-btn {
    display: none;
    flex: 1 0 auto;
}
.FacilitiesSearchHeader-view-map-btn button {
    display: block;
    float: right;
}


@media screen and (max-width:624px) {
    .FacilitiesSearchHeader {
        display: flex;
        flex-direction: column;
    }
    /* Shouldn't have to but won't respect height: 100% */
    .FacilitiesSearchHeader-section1-logo {
        padding-top: 0.8rem;
        padding-bottom: 0.4rem;
    }
    /* On mobile, we want to be able to toggle to map */
    .FacilitiesSearchHeader-view-map-btn {
        display: block;
    }
}