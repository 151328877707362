.BrandingLogo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: column; */
    gap: 0.7rem;
}
.BrandingLogo-text {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.3px;
    text-decoration: none;
    text-align: center;
    @apply text-brand;
}
.BrandingLogo-img {
    flex: 0 0 auto;
    height: 30px;
    width: 30px;
    margin-top: 5px;
}