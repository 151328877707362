.FacilityList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @apply gap-3;
}

.FacilityList__facility-count {
  @apply
        text-gray-400
        text-sm
        pb-3;
}