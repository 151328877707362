.LocationSearchInput {
    /* background-color: white; */
    /* padding: 24px; */
    /* color if overriding Button text color.... hmmm */
    /* color: #808080; */
    /* margin: 0 auto; */
    /* border: 1px solid #cecece; */
    cursor: pointer;
}
.LocationSearchInput-text-input {
    /* background-color: white; */
    /* padding: 1rem 1rem 0.8rem 1rem; */
    /* height: 50px; */
    /* border-radius: 30px; */
    /* color: #808080; */
    /* margin: 0 auto 0.5rem auto; */
    border: 1px solid var(--brand-color);
    cursor: pointer;
    @apply py-3 px-6 text-gray-500 text-base bg-white focus:ring rounded-full
}
.LocationSearchInput-text-input.inactive {
    border: 1px solid #dfdfdf;
}

.LocationSearchInput input {
    border: 0px;
    width: 100%;
    height: 100%;
}

.LocationSearchInput-near-me {
    display: flex;
    flex-direction: column;
}

.LocationSearchInput-suggestions-container h3 {
    margin: 1rem 0 0.2rem 0;
    text-align: left;
    font-size: 16px;
    font-weight: 800;
}

.LocationSearchInput-suggestion {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
}
.LocationSearchInput-suggestion:hover {
    background-color: #f5f5f5;
}

.LocationSearchInput-campground-suggestion-primary {
    padding: auto;
}
.LocationSearchInput-campground-suggestion-secondary {
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    color: #a0a0a0
}