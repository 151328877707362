.LocationInputButton {
    /* background-color: white; */
    /* padding: 1rem 1rem 0.8rem 1rem; */
    /* border-radius: 30px; */
    /* color: #808080; */
    /* height: 50px; */
    /* margin: 0 auto; */
    border: 1px solid #cecece;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    @apply py-3 px-6 text-gray-500 text-base bg-white hover:bg-gray-50 focus:ring rounded-full shadow-md;
}

.LocationInputButton-modal-container {
    position: relative;
}