/* TODO: Consider managing assets locally: https://developers.arcgis.com/javascript/latest/es-modules/#managing-assets-locally */
@import "https://js.arcgis.com/4.23/@arcgis/core/assets/esri/themes/light/main.css";

:root {
  --brand-color:#6Ab60b;
  --brand-hover-color:#5EA603;
  --gray-100:#FFFFFF;
  --gray-200:#EFF1F2;
  --gray-300:#EFEFEF;
  --gray-350:#EBECED;
  --gray-400:#D4DCE4;
  --gray-500:#B7BCC0;
  --gray-600:#818290;
  --gray-700:#00063C;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Comfortaa, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
